<template>
  <div class="container">
    <b-overlay :show="showOverlay">
      <top-header />
      <div class="flex-container"  v-if="form.UID == null">
          <div>
              <div class="flex-item" v-if="lastNameSearch.length == 0 && emailSearch == 0">
                <b-input-group prepend="First Name:" class="my-3">
                  <b-form-input v-model="firstNameSearch" v-on:input="searchOnEnter('firstName')" placeholder="search on all or part"></b-form-input>
                  <b-input-group-append v-if="firstNameSearch.length >= 1"><b-button @click="firstNameSearch = ''"> <b-icon icon="x-circle-fill" font-scale="0.95"></b-icon></b-button></b-input-group-append>
                </b-input-group>          
              </div>
              <div class="flex-item" v-if="firstNameSearch.length == 0 && emailSearch == 0">
                <b-input-group prepend="Last Name:" class="my-3">
                  <b-form-input v-model="lastNameSearch" v-on:input="searchOnEnter('lastName')" placeholder="search on all or part"></b-form-input>
                  <b-input-group-append v-if="lastNameSearch.length >= 1"><b-button @click="lastNameSearch = ''"> <b-icon icon="x-circle-fill" font-scale="0.95"></b-icon></b-button></b-input-group-append>
                </b-input-group>          
              </div>
              <div class="flex-item" v-if="firstNameSearch.length == 0 && lastNameSearch.length == 0">
                <b-input-group prepend="Email:" class="my-3">
                  <b-form-input v-model="emailSearch" v-on:input="searchOnEnter('email')" placeholder="search on all or part"></b-form-input>
                  <b-input-group-append v-if="emailSearch.length >= 1"><b-button @click="emailSearch = ''"> <b-icon icon="x-circle-fill" font-scale="0.95"></b-icon></b-button></b-input-group-append>                
                </b-input-group>          
              </div>
              <div class="flex-item" v-if="firstNameSearch.length == 0 && lastNameSearch.length == 0 && emailSearch == 0">
                  <b-input-group prepend="Race No.:" class="my-3">
                    <b-form-input v-model="uidSearch" v-on:focus="resetSearchFields" placeholder="enter race no."></b-form-input>
                    <b-input-group-append><b-button @click="getRegistration(null)" :disabled="(uidSearch == null || uidSearch.length == 0)" :variant="(uidSearch == null || uidSearch.length == 0)? '':'success'"> <b-icon icon="search" font-scale="0.95"></b-icon></b-button></b-input-group-append>
                  </b-input-group>             
              </div>
          </div>      
      </div>
      <div class="flex-container" v-else>
        <div>
              <div class="flex-item">
                <b-input-group prepend="Entrant Id:" class="my-3 w-50">
                  <b-form-input v-model="form.UID" readonly></b-form-input>
                </b-input-group>
                <b-input-group prepend="First Name:" class="my-3">
                  <b-form-input v-model="form.firstName" :state="firstNameState"></b-form-input>
                </b-input-group>         
            </div>
            <div class="flex-item">
              <b-input-group prepend="Last Name:" class="my-3">
                <b-form-input v-model="form.lastName" :state="lastNameState"></b-form-input>
              </b-input-group>             
            </div>   
            <div class="flex-item">
                <b-input-group prepend="Mobile Number:" class="my-3">
                  <b-form-input type="tel" v-model="form.mobileNumber"></b-form-input>
                </b-input-group>             
            </div>
              <div class="flex-item">
                <b-input-group prepend="Email:" class="my-3">
                  <b-form-input   v-model="form.email"></b-form-input>
                </b-input-group>
           <!--        {{form.email.length + " " + resendEmailDisabled }}   -->                  
            </div>
            <div class="flex-item">
              <b-input-group prepend="Car Registration:" class="my-3">
                <b-form-input  v-model="form.carReg"></b-form-input>
              </b-input-group>             
            </div>
          <div class="flex-item my-3  mt-4">
            <div id="addressContainer" class="py-4">
              <address-component :addressData="form.address" />
            </div>
          </div>                     
        </div>
        <div>
          <div class="flex-item">
            <b-input-group prepend="Select a distance" class="my-3" v-if="form.subEventSelected != null">
                <b-form-select v-model="form.eventSelected" 
                :options="eventOptions"
                :state="eventState">
                </b-form-select>
            </b-input-group>
          </div> 
          <div class="flex-item"> 
              <b-input-group prepend="Emergency Number" class="my-3">
                <b-form-input type="tel"  v-model="form.emergencyNumber" :state="emergencyNumberState"></b-form-input>
              </b-input-group>                
          </div>
          <div class="flex-item">
            <b-input-group prepend="Person to Contact" class="my-3">
              <b-form-input  v-model="form.emergencyContact" :state="emergencyContactState"></b-form-input>
            </b-input-group>         
          </div>
          <div class="flex-item">
            <b-input-group prepend="Retlationship to Contact" class="my-3">
              <b-form-input  v-model="form.emergencyRelationship" :state="emergencyContactRelationshipState"></b-form-input>
            </b-input-group>         
          </div>
          <div class="flex-item">
            <b-input-group prepend="Meal options" class="my-3">
                <b-form-select v-model="form.strMealChoice" 
                :options="mealOptions"
                :state="mealOptionState"
                >
                </b-form-select>
            </b-input-group>
          </div>
          <div class="flex-item mt-4">
            <h5>Dietry Requirements</h5>
            <b-form-textarea
              id="dietryTextarea"
              v-model="form.strDietary"
              placeholder=""
              maxlength="750"
              rows="3"
              max-rows="6"
            ></b-form-textarea>
          </div>
          <div class="flex-item mt-4">
            <h5>Comments</h5>
            <b-form-textarea
              class="mt-2"
              id="commentsTextarea"
              v-model="form.strComments"
              placeholder=""
              maxlength="750"
              rows="3"
              max-rows="6"
            ></b-form-textarea>
          </div>
            <div class="flex-item my-4">
                    <b-button block @click="newSearch"  variant="primary">New search</b-button> 
            </div>
            <div class="flex-item">            
              <b-button block v-if="showSaveButton > 1" @click="updateRegistration" variant="success" :disabled="saveDisabled">Save Changes</b-button> 
              <b-button block v-else @click="resendEmail" variant="primary" :disabled="resendEmailDisabled || form.email.length < 5">Resend Email</b-button>  
            </div>
        </div>   

      </div>
               <div class="" v-if="firstNameSearch.length >=1 || lastNameSearch.length >=1 || emailSearch.length >=1 || showNotFound">
             
                  <b-pagination
                  v-if="searchResults.length >= 6"
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  align="fill"
                  size="sm"
                  class="my-0"
                ></b-pagination>
                <b-table     
                :striped="striped"
                :bordered="bordered"
                :borderless="borderless"
                :outlined="outlined"
                :small="small"
                :hover="hover"
                :dark="dark"
                :fixed="fixed"
                :foot-clone="footClone"
                :no-border-collapse="noCollapse"
                :items="searchResults"
                :fields="fields"
                :head-variant="headVariant"
                :table-variant="tableVariant"
                show-empty
                :per-page="perPage"
                :current-page="currentPage"
                responsive="md"
                >
                    <template #cell(actions)="row">
                    <b-button size="sm" @click="getRegistration(row.item.UID)" class="mr-1" variant="success">
                      View
                    </b-button>
                  </template>
                </b-table> 
                <h6 class="text-primary">{{searchResults.length}} Entrant(s) found that match your search criteria</h6>      
              </div>
      <template #overlay>
        <custom-overlay :overlayText="overlayText"/> 
    </template> 
    </b-overlay>
  </div>
</template>

<script>
import {AdminMixin} from '@/mixins/AdminMixin'
import {CommonMixin} from '@/mixins/CommonMixin'
import AddressComponent from './AddressComponent.vue'
import CustomOverlay from '@/components/CustomOverlay'
import {RepositoryFactory} from '../api/RepositoryFactory'
import TopHeader from './topHeader.vue'
const RepoPost = RepositoryFactory.post('registration')
const RepoGet = RepositoryFactory.get('registration')
export default {
  name: 'AdminDashboard',
  mixins: [AdminMixin, CommonMixin],
  components: {AddressComponent, CustomOverlay, TopHeader},
  data(){
      return {
        showSaveButton: 0,
        resendEmailDisabled: false,
        firstNameSearch: "",
        lastNameSearch: "",
        emailSearch: "",
        uidSearch: null,
        showNotFound: false,
        showOverlay: false,
        overlayText: null,
        entrants: null,
        searchResults:  [],
        striped: true,
        bordered: true,
        borderless: false,
        outlined: false,
        small: true,
        hover: true,
        dark: false,
        fixed: false,
        footClone: false,
        headVariant: null,
        tableVariant: '',
        noCollapse: false,
        filter: null,
        currentPage: 1,
        totalRows: 0,
        perPage: 5,
        filterOn: [],
        fields: [
          {
          key: 'UID',
          label: 'Race No.',
          sortable: true,
          }, 
          {
            key: 'First_Name',
            label: 'First Name',
            sortable: true
          }
          ,
          {
            key: 'Last_Name',
            label: 'Last Name',
            sortable: true
          }
          ,
          {
            key: 'Email',
            label: 'Email',
            sortable: true,
            thClass: 'd-none', 
            tdClass: 'd-none' 
          },
          { key: 'actions', label: 'Actions' }
        ]        
      }
  },
  methods:{
    async updateRegistration()
    {
        this.showOverlay = true
        this.overlayText = "Updating Registration"
        await RepoPost.updateRegister(this.form).then(()=>{
          this.displayOverlay("Changes Saved")
          this.showSaveButton = 0
          this.resendEmailDisabled = false
        })
    },
    async resendEmail()
    {
       this.showOverlay = true
       this.overlayText = "Sending Email"
       let formData = new FormData()

      formData.append("appKey", this.$store.state.appKey)
      formData.append("secToken", this.$store.state.secToken )
      formData.append("uid" , this.form.UID)
       await RepoPost.resendEmail(formData).then(()=>{
        setTimeout(()=>{
            this.overlayText = "Email Sent"   
          setTimeout(()=>{
            this.showOverlay = false
          }, 3000)
        }, 3000)
         }
       )
    },
    async getRegistrations()
    {
      let formData = new FormData()

      formData.append("appKey", this.$store.state.appKey)
      formData.append("secToken", this.$store.state.secToken )

       await RepoPost.getEntries(formData).then(response =>
        {   
          console.log(response)  
          this.entrants = response.data
          this.totalRows = response.data.length - 1// this is required for paignation
        }
       )
    },
    async getRegistration(UID)
    {
      this.showNotFound = false;
      if(this.uidSearch == null)
      {
       this.uidSearch = UID       
      }

      let formData = new FormData()
      formData.append("appKey", this.$store.state.appKey)
      formData.append("secToken", this.$store.state.secToken )
      formData.append("uid" , this.uidSearch)

      await RepoGet.getEntry(formData).then(response =>
      {   
        if(response.data.length === 0)
        {
          this.showNotFound = true          
        }
        else
        {
          this.form.UID = response.data[0].UID            
          this.form.address.addressLine1 = response.data[0].Address_1
          this.form.address.addressLine2 = response.data[0].Address_2
          this.form.address.addressLine3 = response.data[0].Address_3
          this.form.address.county = response.data[0].County
          this.form.address.postcode = response.data[0].Post_Code
          this.form.firstName = response.data[0].First_Name
          this.form.lastName = response.data[0].Last_Name
          this.form.dob = response.data[0].DOB
          this.form.mobileNumber = response.data[0].Mobile_No
          this.form.email = response.data[0].Email
          this.form.carReg = response.data[0].Car_Registration
          this.form.emergencyNumber = response.data[0].Tel_No_Emergency_No
          this.form.emergencyContact = response.data[0].Emergency_Contact
          this.form.emergencyRelationship = response.data[0].emergencyRelationship
          this.form.subEventSelected = response.data[0].event
          this.form.eventSelected = response.data[0].event
          this.form.paymentType = response.data[0].Cq_or_BT
          this.form.strDietary = response.data[0].strDietary
          this.form.strMealChoice = response.data[0].strMealChoice
          this.form.strComments = response.data[0].strComments
          this.form.over18Selected = response.data[0].over18

      
          this.showSaveButton = 0
        }
          
          this.resetSearchFields()

      })
    },
    searchOnEnter(searchType){
        this.uidSearch = null
        console.log(searchType)
        switch(searchType)
        {
          case "firstName":
            this.lastNameSearch = ""
            this.emailSearch = ""
            this.searchResults = this.entrants.filter(item=> item.First_Name.toLowerCase().indexOf(this.firstNameSearch.toLowerCase()) > -1)
          break;
          case "lastName":
            this.firstNameSearch = ""
            this.emailSearch = ""
            this.searchResults = this.entrants.filter(item=> item.Last_Name.toLowerCase().indexOf(this.lastNameSearch.toLowerCase()) > -1)
          break;
          case "email":
            this.lastNameSearch = ""
            this.lastNameSearch = ""
            this.searchResults = this.entrants.filter(item=> item.Email.toLowerCase().indexOf(this.emailSearch.toLowerCase()) > -1)
          break;
        }
    },
    displayOverlay(overlaytext){
          setTimeout(()=>{
          this.overlayText = overlaytext   
        setTimeout(()=>{
          this.showOverlay = false
          this.overlayText = null
        }, 3000)
      }, 3000)
    },
    resetSearchFields(){
      this.firstNameSearch = ""
      this.lastNameSearch = ""
      this.emailSearch = ""
      this.searchResults = []
      this.uidSearch = null
    },
    newSearch()
    {
      this.getRegistrations()
      this.form.UID = null         
      this.form.address.addressLine1 = null 
      this.form.address.addressLine2 = null 
      this.form.address.addressLine3 = null 
      this.form.address.county = null 
      this.form.address.postcode = null 
      this.form.firstName = null 
      this.form.lastName = null 
      this.form.dob = null 
      this.form.mobileNumber = null 
      this.form.email = null 
      this.form.carReg = null 
      this.form.emergencyNumber = null 
      this.form.emergencyContact = null 
      this.form.emergencyRelationship = null 
      this.form.subEventSelected = null 
      this.form.eventSelected = null 
      this.form.paymentType = null 
      this.form.strDietary = null 
      this.form.strMealChoice = null 
      this.form.strComments = null 
      this.form.over18Selected = null 
      this.firstNameSearch = ""
      this.lastNameSearch = ""
      this.emailSearch = ""
      this.uidSearch = null
      this.showNotFound = false
      this.showSaveButton = 0
      this.resendEmailDisabled = false
    }
  },
  mounted()
  {
    this.getRegistrations()
  },
  computed: {
      participantState(){        
        return (this.firstNameState && this.lastNameState  && this.form.address.addressLine1.length >= 1  && this.form.address.postcode.length >= 1) ? "complete" : "incomplete"
      },
      participantStateText(){        
        return (this.firstNameState && this.lastNameState  && this.form.address.addressLine1.length >= 1  && this.form.address.postcode.length >= 1)? "Complete" : "Incomplete"
      },
      firstNameState() {
        return this.form.firstName.length >= 1
      },
      lastNameState() {
        return this.form.lastName.length >= 1
      },
      eventState()
      {
         return  this.form.eventSelected != null
      },
      eventOptionsState()
      {
         return (this.eventState)? "complete" : "incomplete"
      },
      eventOptionsStateText()
      {
         return (this.eventState)? "Complete" : "Incomplete"
      },
      additionalInfoState()
      {
         return (this.emergencyContactState && this.emergencyNumberState && this.form.strMealChoice != 'select' && this.form.emergencyRelationship.length >= 2)? "complete" : "incomplete"
      },
      mealOptionState()
      {
        return this.form.strMealChoice != 'select'
      },
      emergencyContactRelationshipState()
      {
        return this.form.emergencyRelationship.length >= 2
      },
      emergencyContactState()
      {
        return this.form.emergencyContact.length >= 2
      },      
      emergencyNumberState()
      {
        return this.form.emergencyNumber.length >= 11
      },
      saveDisabled()
      {
        return (this.eventOptionsState === 'complete' && this.participantState  === 'complete' && this.additionalInfoState  === 'complete' )? false : true
      }
  },
    watch:{
    form:{
        immediate: false,
        deep: true,
        handler(){ 
          ++this.showSaveButton 
          console.log(this.showSaveButton )
          if(this.showSaveButton >=2)
          {            
            this.resendEmailDisabled = true
          }
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.flex-container {
  display: flex;
  flex-wrap: nowrap;
}

.flex-container>div {
  width: 50%;
  margin: 10px;
  line-height: 75px;
  font-size: 30px;
}

.flex-item-left
{
  text-align: left;
}

.flex-item-right
{
  text-align: right;
}
  
.input-group-text
{
  min-width: 110px;
}

@media only screen and (max-width: 992px) {

  .flex-container {
    flex-direction: column;
  }
  
  .flex-container>div {
  width: 100%;
  margin: 10px;
  line-height: 75px;
  font-size: 30px;
  }
    
  .input-group-text
  {
    font-size: 0.9rem;
    min-width: 100px;
  }

}
</style>
